import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Colophon, Layout, NewsletterForm, SEO } from '../components';

import CONSTANTS from '../util/constants';

const useStyles = makeStyles(theme => ({
  attribution: {},
  attributionLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '40vh',
    width: '100%',
    alignItem: 'center',
  },
  heroImageContainer: props => ({
    flexGrow: 120,
    minHeight: '600px',
    margin: 0,
    backgroundImage: `url(${
      props && props.heroImage && props.heroImage.url
        ? props.heroImage.url
        : CONSTANTS.DEFAULT_HERO_IMAGE.url
    })`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }),
  postContainer: {
    maxWidth: '75ch',
    fontSize: '1.2rem',
    lineHeight: '1.85rem',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  spacing: {
    marginTop: theme.spacing(12),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
  },
}));

const PostLayout = props => {
  const { children, pageContext } = props;
  const {
    /* author , */ date,
    heroImage,
    subtitle,
    /* tags, */ title,
  } = pageContext.frontmatter;

  const heroImageToUse = heroImage || CONSTANTS.DEFAULT_HERO_IMAGE;
  const classes = useStyles({ heroImage: heroImageToUse });

  return (
    <Layout>
      <SEO title={title} description={pageContext.excerpt} />
      <div className={classes.root}>
        <Grid>
          <Grid item>
            <Typography
              variant="overline"
              component="div"
              color="secondary"
              align="center"
            >
              {date}
            </Typography>
            <Typography variant="h1" align="center">
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
        <Container
          disableGutters
          maxWidth={false}
          className={classes.heroImageContainer}
        >
          <span />
        </Container>
        <Typography
          variant="overline"
          component="figcaption"
          className={classes.attribution}
        >
          Photo by{' '}
          <a
            className={classes.attributionLink}
            href={heroImageToUse.author.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {heroImageToUse.author.name}
          </a>{' '}
          on{' '}
          <a
            className={classes.attributionLink}
            href="https://unsplash.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsplash
          </a>
        </Typography>
        <Grid>
          <Grid item>
            <Container maxWidth={false} className={classes.postContainer}>
              {children}
              <Colophon />
              <div className={classes.spacing} />
            </Container>
          </Grid>
        </Grid>
        <Grid className={classes.cta}>
          <Grid item>
            <Container maxWidth={false} className={classes.postContainer}>
              <NewsletterForm />
            </Container>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default PostLayout;
